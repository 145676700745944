body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.swal2-styled {
  background-color: #50c7c3 !important;
  box-shadow: none !important;
}
.swal2-cancel {
  background-color: #edf2f9 !important;
  color: black !important;
}
.swal2-title {
  font-size: 22px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  color: black;
}
.ql-container.ql-snow {
  border: none !important;
}
.css-13cymwt-control {
  border: 1px solid #e3e7eb !important;
  background: white !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
  background-color: rgb(237, 236, 236);
  width: 100px;
  height: 100px;
  min-width: 100px;
  border: 1px dashed gray;
  border-radius: 0.6rem;
}

.cancel_icon {
  position: absolute;
  top: 3px;
  right: 2px;
  cursor: pointer;
}
.upload_spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: green;
  height: 100vh;
  min-height: 100%;
  width: 100%;
}
.upload-btn {
  background: #50c7c3 !important;
  border: none;
  color: white;
}
.upload-btn:hover {
  background: #53d1cd;
  color: white;
}
.badge_info {
  display: block;
  background-color: var(--bs-primary);
  padding: 10px;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  color: rgb(129, 128, 128);
}
.form-group.required .control-label:after {
  content: "*";
  color: red;
}
.hide-resize-observer {
  display: none !important;
}
#webpack-dev-server-client-overlay-div {
  display: none !important;
}
.post_badge {
  width: fit-content;
  color: white;
  font-size: 10px;
  background: #50c7c3;
  font-weight: bold;
}
.delete-media{
  position: absolute;
  top: 0;
}
